import React, { useEffect, useState } from 'react';
import {
  api,
  GLOBAL_COLORS,
  useIblTranslation,
} from '@iblai/ibl-web-react-common';
import './App.css';

import '@iblai/ibl-web-react-common/index.css';
import route from './routes';
import { AppDataContext, isJSON } from '@iblai/ibl-web-react-common';
import { RouterProvider } from 'react-router-dom';
import './i18n';
import { createGlobalStyle } from 'styled-components';

import '@shoelace-style/shoelace/dist/themes/light.css';
import { setBasePath } from '@shoelace-style/shoelace/dist/utilities/base-path';
import useNotifications from "./hooks/useNotifications";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";
import SlAlert from "@shoelace-style/shoelace/dist/react/alert";

setBasePath('https://cdn.jsdelivr.net/npm/@shoelace-style/shoelace@2.18.0/cdn/');

//window.api = api

const initiateAppContextData = () => {
  console.log('############### initiateAppContextData');
  return {
    ...localStorage,
    userData: isJSON(localStorage['userData'])
      ? JSON.parse(localStorage['userData'])
      : null,
    ibl_user_public_metadata: isJSON(
      localStorage.getItem('ibl_user_public_metadata')
    )
      ? JSON.parse(localStorage.getItem('ibl_user_public_metadata'))
      : null,
    userMetaData: {},
  };
};

function App() {
  const [appData, setAppData] = useState(() => {
    return {
      ...initiateAppContextData(),
    };
  });
  const { translate } = useIblTranslation();
  const {notification, setNotification, notificationRef} = useNotifications()

  useEffect(() => {
    api.initiateAxdClientsWithToken(appData.axd_token);
  }, []);

  useEffect(() => {
    if (appData.userMetaData?.language) {
      translate(appData.userMetaData.language);
    }
  }, [appData.userMetaData]);

  return (
    <>
      <GlobalStyles />
      <SlAlert ref={notificationRef} variant={notification?.success ? "success" : "danger"} duration={5000} closable>
        <SlIcon slot="icon" name={notification?.success ? "check2-circle" : "exclamation-octagon"} />
        <strong>{notification?.msg}</strong>
      </SlAlert>
      <AppDataContext.Provider value={{ appData, setAppData,  notification, notificationRef, setNotification}}>
        <RouterProvider router={route(appData, setAppData)} />
      </AppDataContext.Provider>
    </>
  );
}

const GlobalStyles = createGlobalStyle`
  html {
    --grey: ${GLOBAL_COLORS.grey};
    --font: ${GLOBAL_COLORS.font};
    --accent: ${GLOBAL_COLORS.primary};
    --accenthover: ${GLOBAL_COLORS.secondary};
    --accent-2: ${GLOBAL_COLORS.tertiary};
    --gainsboro: ${GLOBAL_COLORS.grey_secondary};
    --white-smoke: ${GLOBAL_COLORS.white_smoke};
    --white: white;
    --black: black;
    --3: white;
  }
`;

export default App;
