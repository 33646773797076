import React, {useContext, useRef, useState} from 'react';
import {getTenant, isJSON} from "@iblai/ibl-web-react-common";
import {
    createCourseAPI, createCourseExtraSection,
    createCourseSection,
    updateCourseMetaDataAPI,
    uploadFileToCourseStudio
} from "./course";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'; // Import UTC plugin
import timezone from 'dayjs/plugin/timezone';
import {getYouTubeVideoId} from "../helpers";
import {useNavigate} from "react-router-dom";
import { AppDataContext } from '@iblai/ibl-web-react-common';
dayjs.extend(utc);
dayjs.extend(timezone);

const useCourseCreation = () => {
    const {notification, setNotification, notificationRef} = useContext(AppDataContext)
    const navigate = useNavigate()
    const TABS = [
        //"Settings",
        "Additional",
        "Content",
    ]
    const [currentTab, setCurrentTab] = useState(TABS[0])
    const isLastTab = () => {
        const currentTabIndex = TABS.findIndex(pre=>pre===currentTab)
        return currentTabIndex === (TABS.length - 1)
    }

    const goToNextTab = () => {
        const currentTabIndex = TABS.findIndex(pre=>pre===currentTab)
        const isTheLastTab = currentTabIndex === (TABS.length - 1)
        setCurrentTab(isTheLastTab ? currentTab : TABS[currentTabIndex+1])
        if(isTheLastTab){
            handleFormSubmit()
        }
    }


    const [submitting, setSubmitting] = useState(false)
    const defaultFields  = {
        display_name:"",
        courseKey:"",
        //META DATA FIELDS
        description:"",
        duration:"",
        tags:[],//["topic1", "topic2"]
        topics:[],//["topic1", "topic2"]
        course_image_asset_path:"",//corresponds to url under studio image upload response
        course_image_name:"",//corresponds to display_name
        //COURSE SECTION FIELDS
        section_title:"",
        section_locator:"",
        sub_section_title:"",
        sub_section_locator:"",
        unit_title:"",
        unit_locator:"",
        video_locator:"",
        //COURSE CONTENT
        youtube_video_url:"",
        //AUTO POPULATED
        start_date:"",
        //TO BE DELETED BEFORE POST
        file:""
    }
    const [fields, setFields] = useState(defaultFields)
    const defaultStudioImageUploadResponse = {
        "asset": {
            "display_name": "",
            "content_type": "",
            "date_added": "",
            "url": "",
            "external_url": "",
            "portable_url": "",
            "thumbnail": "",
            "locked": false,
            "static_full_url": "",
            "id": ""
        },
        "msg": ""
    }
    const [imageCardUploadResponse, setImageCardUploadResponse] = useState(defaultStudioImageUploadResponse)

    const handleFormSubmit = async () => {

        if(!(fields?.display_name && fields?.description && fields?.duration &&
        fields?.tags && fields?.topics
        )){
            setCurrentTab(TABS[0])
            setNotification({
                success: false,
                msg: "Fill out all required fields."
            })
            return
        }
        if(!(fields?.section_title && fields?.sub_section_title &&
        fields?.unit_title && fields?.youtube_video_url
        )){
            setCurrentTab(TABS[1])
            setNotification({
                success: false,
                msg: "Fill out all required fields."
            })
            return
        }
        setSubmitting(true)
        const currentTenantData = localStorage.getItem('current_tenant')
        const org = isJSON(currentTenantData) ? JSON.parse(currentTenantData)?.org : null
        const courseData = await createCourseAPI({
            org,
            display_name:fields.display_name
        })
        if(!courseData?.course_key){
            setSubmitting(false)
            setNotification({
                msg: "An error occurred during initial course creation",
                success: false
            })
            return
        }
        const courseKey = courseData.course_key
        /*const formData = new FormData()
        formData.append('file', fields.file)
        const fileUploadData = await uploadFileToCourseStudio(formData, courseKey)
        if(!fileUploadData?.asset?.id){
            setSubmitting(false)
            alert('error on image upload')
            //TODO notification error on image upload
            //return
        }*/
        const courseKeySplit = courseKey.split('+');
        const courseID = courseKeySplit[1];
        const courseRun = courseKeySplit[2];
        const courseMetaDataFields = {
            org,
            "course_id": courseID,
            /*course_image_name:fileUploadData?.asset?.display_name ?? "",
            course_image_asset_path:fileUploadData?.asset?.url ?? "",*/
            "run": courseRun,
            "language": "en",
            "start_date": dayjs().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
            "end_date": null,
            "enrollment_start": null,
            "enrollment_end": null,
            "certificate_available_date": null,
            "certificates_display_behavior": "end",
            "syllabus": null,
            "title": "",
            "subtitle": "",
            "duration": "",
            "description": "",
            "short_description": "",
            "about_sidebar_html": "",
            "intro_video": null,
            "effort": null,
            "license": null,
            "pre_requisite_courses": [],
            "entrance_exam_enabled": "",
            "entrance_exam_id": "",
            "entrance_exam_minimum_score_pct": "50",
            "self_paced": false,
            "learning_info": [],
            "instructor_info": {
                "instructors": []
            },
            "slug": null,
            "platform_key": getTenant(),
            "course_key": courseKey
        }
        const updatedCourseMetaDataFields = await updateCourseMetaDataAPI(courseMetaDataFields)
        if(!updatedCourseMetaDataFields?.course_key){
            setSubmitting(false)
            setNotification({
                msg: "An error occurred during course metadata settings update.",
                success: false
            })
            return
        }
        const sectionData = await createCourseSection({
            "parent_locator": `block-v1:${org}+${courseID}+${courseRun}+type@course+block@course`,
            "category": "chapter",
            "display_name": fields?.section_title
        })
        if(!sectionData?.locator){
            setSubmitting(false)
            setNotification({
                msg: "An error occurred during course section creation.",
                success: false
            })
            return
        }
        const subSectionData = await createCourseSection({
            "parent_locator": sectionData?.locator,
            "category": "sequential",
            "display_name": fields?.sub_section_title
        })
        if(!subSectionData?.locator){
            setSubmitting(false)
            setNotification({
                msg: "An error occurred during course sub-section creation.",
                success: false
            })
            return
        }
        const unitData = await createCourseSection({
            "parent_locator": subSectionData?.locator,
            "category": "vertical",
            "display_name": fields?.unit_title
        })
        if(!unitData?.locator){
            setSubmitting(false)
            setNotification({
                msg: "An error occurred during course unit creation.",
                success: false
            })
            return
        }
        const videoData = await createCourseSection({
            "category": "video",
            "type": "video",
            "parent_locator": unitData?.locator
        })
        if(!videoData?.locator){
            setSubmitting(false)
            setNotification({
                msg: "An error occurred during course video section creation.",
                success: false
            })
            return
        }
        const youtubeVideoData = await createCourseExtraSection(videoData?.locator, {
            "id": videoData?.locator,
            "display_name": "Video",
            "category": "video",
            "metadata": {
                "display_name": "Video",
                "html5_sources": [],
                "edx_video_id": "",
                "youtube_id_1_0": getYouTubeVideoId(fields?.youtube_video_url)
            },
            "studio_url": null,
            "child_info": null,
            "ancestor_info": null,
            "edited_on": null,
            "edited_by": null,
            "published": null,
            "published_on": null,
            "published_by": null,
            "has_children": true,
            "has_changes": null,
            "visibility_state": null,
            "released_to_students": null,
            "release_date": null,
            "release_date_from": null,
            "currently_visible_to_students": null,
            "due_date": null,
            "format": null,
            "course_graders": null,
            "graded": null,
            "start": null,
            "due": null,
            "has_explicit_staff_lock": null,
            "ancestor_has_staff_lock": null,
            "staff_lock_from": null,
            "staff_only_message": null,
            "has_partition_group_components": null,
            "actions": null,
            "is_header_visible": null,
            "explanatory_message": null,
            "group_access": null,
            "user_partitions": null,
            "highlights": [],
            "highlights_enabled": false,
            "highlights_enabled_for_messaging": false,
            "highlights_preview_only": true,
            "highlights_doc_url": "",
            "courseKey": courseKey
        })
        if(!youtubeVideoData?.id){
            setSubmitting(false)
            setNotification({
                msg: "An error occurred during the course youtube url update.",
                success: false
            })
            return
        }
        const publishUnitData = await createCourseExtraSection(unitData?.locator, {
            "publish": "make_public"
        })
        if(!publishUnitData?.id){
            setSubmitting(false)
            setNotification({
                msg: "An error occurred during the course publishing process.",
                success: false
            })
            return
        }
        setNotification({
            msg: `${fields?.display_name} course created successfully.`,
            success: true
        })
        setSubmitting(false)
        navigate(`/studio/authoring/courses/${courseKey}`)
    }

    return {
        submitting,
        fields,
        setFields,
        imageCardUploadResponse,
        setImageCardUploadResponse,
        handleFormSubmit,
        notificationRef,
        notification,
        TABS,
        currentTab,
        isLastTab,
        goToNextTab,
        setCurrentTab
    }
};

export default useCourseCreation;