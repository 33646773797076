import React, {useEffect, useRef, useState} from 'react';
import { useTranslation } from 'react-i18next';
import './StudioAuthoringCourseCreationViewContent.css';
import {Link} from 'react-router-dom';
import {getTenant, IBLSpinLoader} from "@iblai/ibl-web-react-common";
import {slugify} from "../../../hooks/helpers";
import CreatableSelect from "react-select/creatable";
import useCourseCreation from "../../../hooks/utils/useCourseCreation";

const StudioAuthoringCourseCreationViewContent = () => {
  const { t } = useTranslation();
  const {submitting, fields, setFields, imageCardUploadResponse,
    setImageCardUploadResponse, goToNextTab,
    isLastTab, currentTab, TABS, setCurrentTab} = useCourseCreation()

  const handleInputChange = (e) => {
    setFields(old=>({
      ...old,
      [e?.target?.name]:e?.target?.value
    }))
  }

  //SELECT CREATABLE
  const [tagInputValue, setTagInputValue] = React.useState('');
  const handleTagsKeyDown = (event) => {
    if (!tagInputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        setFields((old)=>({
          ...old,
          tags:[
              ...old.tags,
            createOption(tagInputValue)
          ]
        }))
        //setValue((prev) => [...prev, createOption(tagInputValue)]);
        setTagInputValue('');
        event.preventDefault();
    }
  };
  const [topicInputValue, setTopicInputValue] = React.useState('');
  const handleTopicsKeyDown = (event) => {
    if (!topicInputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        setFields((old)=>({
          ...old,
          topics:[
            ...old.topics,
            createOption(topicInputValue)
          ]
        }))
        //setValue((prev) => [...prev, createOption(tagInputValue)]);
        setTopicInputValue('');
        event.preventDefault();
    }
  };
  const tagsComponents = {
    DropdownIndicator: null,
  };
  const topicsComponents = {
    DropdownIndicator: null,
  };


  const createOption = (label) => ({
    label,
    value: `${slugify(label)}-${Date.now()}`,
  });

  //IMAGE CARD UPLOAD
  const [imageCardUploadLoading, setImageCardUploadLoading] = useState(false)
  const imageCardUploadInputElement = useRef(null)
  const MAX_FILE_SIZE = 1024 * 1024 * 2
  const handleImageCardUploadChange = async (e) => {
    if (!e) {
      return;
    }
    const file = e?.target?.files[0];
    if (file.size >= MAX_FILE_SIZE) {
      alert('Image card size must not exceed 2Mb');
      return;
    }
    let fileName = file.name;
    // Check if the file name exceeds 10 characters (excluding the extension)
    if (fileName.length > 20) {
      const fileExtension = fileName.substring(fileName.lastIndexOf('.'));
      const baseName = fileName.substring(0, fileName.lastIndexOf('.'));
      // Truncate the base name and append the extension
      fileName = `${baseName.substring(0, 20 - fileExtension.length)}...${fileExtension}`;
    }
    const blobImg = URL.createObjectURL(file);
    setImageCardUploadResponse((old)=>({
      ...old,
      asset:{
        ...old.asset,
        display_name:fileName,
        external_url:blobImg
      }
    }))
    setFields((old)=>({
      ...old,
      file: file
    }))
    imageCardUploadInputElement.current.value = '';
    //const base64 = await convertToBinary(e);
    /*const formdata = new FormData();
    formdata.append('file', file);
    handleImgUploadToAPI(formdata);*/
  };

  const handleImageCardUploadClick = () => {
    imageCardUploadInputElement.current.click();
  };

  return (
      <>
        <div className="div-block-183">
          <div className="div-block-205">
            <div className="div-block-206"/>
            <div className="text-block-113">Courses | Add new</div>
          </div>
          <div className="div-block-308">
            <Link
                to={"/studio/authoring/courses"}
                className="link-block-22 w-inline-block"
            >
              <div data-w-id="0579eadf-bcf5-cf0e-3353-b66eb69968b8"
                   className="div-block-207 first active follow-user back-to-course-btn">
                <img src="/images/move-left.svg" loading="lazy" alt className="back-to-course-btn-icon"/>
                <div className="text-block-111">Back to all courses</div>
              </div>
            </Link>
          </div>
        </div>
        <div className="w-layout-vflex flex-block-32">
          <div className="w-layout-hflex flex-block-33">
            <div className="div-block-147 new_msg course-creation-form-helper-text">
              <div className="text-block-135 mg-0 smaller">Fill in this form wizard to create
                a new course
              </div>
            </div>
            <div className="div-block-264 f-w course-creation-submit-btn-block">
              <div data-w-id="f2ed8e4f-e0fe-f403-ec28-b3ea4450eabf" onClick={()=>goToNextTab()}
                   className="div-block-207 first active follow-user redeem-btn mg-l16 program-enroll course-creation-form-btn">
                <div className="text-block-111 red-text cf" style={{display:"flex", alignItems:"center", gap:"10px"}}><span>{isLastTab() ? "Submit" : "Next"}</span>{submitting && (
                    <span><IBLSpinLoader size={15} color={"#FFF"}/> </span>)}</div>
              </div>
            </div>
          </div>
          <div data-current="Tab 1" data-easing="ease" data-duration-in={300} data-duration-out={100}
               className="course-creation-tabs-container w-tabs">
            <div className="course-creation-tabs-header-menu w-tab-menu">
              <a data-w-tab="Tab 2" onClick={event => {
                event.preventDefault()
                setCurrentTab(TABS[0])
              }}
                 className={`course-creation-tabs-header-menu-tab-link w100 w-inline-block w-tab-link ${currentTab===TABS[0] ? "w--current" : ""}`}>
                <div className={`w-layout-hflex course-creation-tabs-header-menu-tab-link-nbr-block ${currentTab===TABS[0] ? "active" : ""}`}>
                  <div className="course-creation-tabs-header-menu-tab-link-nbr-label">1</div>
                </div>
                <div className={`course-creation-tabs-header-menu-tab-link-label ${currentTab===TABS[0] ? "active" : ""}`}>Settings</div>
                <div className={`course-creation-tabs-header-menu-tab-seperator`}/>
              </a>
              <a data-w-tab="Tab 3" data-final-tab="true" onClick={event => {
                event.preventDefault()
                setCurrentTab(TABS[1])
              }}
                 className={`course-creation-tabs-header-menu-tab-link w-inline-block w-tab-link ${currentTab===TABS[1] ? "w--current" : ""}`}>
                <div className={`w-layout-hflex course-creation-tabs-header-menu-tab-link-nbr-block ${currentTab===TABS[1] ? "active" : ""}`}>
                  <div className="course-creation-tabs-header-menu-tab-link-nbr-label">2</div>
                </div>
                <div className={`course-creation-tabs-header-menu-tab-link-label ${currentTab===TABS[1] ? "active" : ""}`}>Content</div>
              </a>
            </div>
            <div className="course-creation-tabs-content w-tab-content">
              <div data-w-tab="Tab 2" className={`course-creation-tabs-content-tab-pane w-tab-pane ${currentTab === TABS[0] ? "w--tab-active" :"" }`}>
                <div className="div-block-228 course-creation-tabs-content-block">
                  <div className="form-block-7 w-form">
                    <form onSubmit={e=>{
                      e.preventDefault()
                      goToNextTab()
                    }} id="email-form-5" name="email-form-5" data-name="Email Form 5" method="get"
                          data-wf-page-id="678801fa35532f7aa72dff71"
                          data-wf-element-id="de3fb6ec-41c5-4235-b0f0-bb45d2a0d7ef">
                      <label htmlFor="display_name"
                             className="text-block-139-bd-f-soc">Course
                        name *</label><input id={"display_name"} className="text-field-3 w-input" maxLength={256}
                                             onChange={handleInputChange}
                                             value={fields?.display_name} name="display_name"
                                             data-name="Name 2" placeholder="Course name" type="text"/>
                      <label htmlFor="description_field"
                             className="text-block-139-bd-f-soc">Course
                        description *</label><textarea placeholder="Description" maxLength={5000} id="description_field"
                                                       name="description" data-name="Field 5" value={fields?.description}
                                                       className="textarea-3 w-input ft-size-13" onChange={handleInputChange}/><label
                        htmlFor="duration_field" className="text-block-139-bd-f-soc">Course duration *</label><input
                        className="text-field-3 w-input" maxLength={256} name="duration" onChange={handleInputChange} value={fields?.duration}  data-name="Name 2"
                        placeholder="Duration" type="text" id="duration_field"/>
                      {/*<div className="w-layout-hflex flex-block-31"><label htmlFor="name-3"
                                                                           className="text-block-139-bd-f-soc">Course
                        card image *</label>
                        {
                            !imageCardUploadLoading && imageCardUploadResponse?.asset?.display_name && (
                                <Link target={"_blank"} to={imageCardUploadResponse?.asset?.external_url}
                                      className="link-block-25 w-inline-block"><img src="/images/paperclip-1.svg"
                                                                                    loading="lazy" alt
                                                                                    className="image-151"/>
                                  <div>{imageCardUploadResponse?.asset?.display_name}</div>
                                </Link>
                            )
                        }
                        {
                            imageCardUploadLoading && <IBLSpinLoader size={20} color={"#FFF"}/>
                        }
                        <input
                            ref={imageCardUploadInputElement}
                            onChange={handleImageCardUploadChange}
                            type="file"
                            className="credential-icon-upload-input"
                            accept="image/*"
                        />
                      </div>*/}
                      {/*<div onClick={()=>handleImageCardUploadClick()}
                          className="div-block-207 first active follow-user redeem-btn mg-l16 no-mg-left">
                        <div className="text-block-111 red-text cf">Upload</div>
                      </div>*/}
                      <label htmlFor="tags_fields" className="text-block-139-bd-f-soc">Course tags *</label>
                      <CreatableSelect
                          components={tagsComponents}
                          inputValue={tagInputValue}
                          classNamePrefix={'react-select'}
                          isClearable
                          isMulti
                          menuIsOpen={false}
                          onChange={(newValue) => setFields((old)=>({
                            ...old,
                            tags:newValue
                          }))}
                          onInputChange={(newValue) => setTagInputValue(newValue)}
                          onKeyDown={handleTagsKeyDown}
                          placeholder="Course tags"
                          value={fields?.tags}
                          id={"tags_fields"}
                      />
                      <p className={"form-field-helper"}>Enter course tags. Press 'Enter' or 'Tab' to create a new entry.</p>
                      <label htmlFor="topics_fields" className="text-block-139-bd-f-soc">Course topics *</label>
                      <CreatableSelect
                          components={topicsComponents}
                          inputValue={topicInputValue}
                          classNamePrefix={'react-select'}
                          isClearable
                          isMulti
                          menuIsOpen={false}
                          onChange={(newValue) => setFields((old)=>({
                            ...old,
                            topics:newValue
                          }))}
                          onInputChange={(newValue) => setTopicInputValue(newValue)}
                          onKeyDown={handleTopicsKeyDown}
                          placeholder="Course topics"
                          value={fields?.topics}
                          id={"topics_fields"}
                      />
                      <p className={"form-field-helper"}>Enter course topics. Press 'Enter' or 'Tab' to create a new entry.</p>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
              </div>
              <div data-w-tab="Tab 3" className={`course-creation-tabs-content-tab-pane w-tab-pane ${currentTab === TABS[1] ? "w--tab-active" :"" }`}>
                <div className="div-block-228 course-creation-tabs-content-block">
                  <div className="form-block-7 w-form">
                    <form id="email-form-5" name="email-form-5" data-name="Email Form 5" method="get"
                          data-wf-page-id="678801fa35532f7aa72dff71"
                          data-wf-element-id="de3fb6ec-41c5-4235-b0f0-bb45d2a0d833"><label htmlFor="section_title"
                                                                                           className="text-block-139-bd-f-soc">Section
                      title *</label><input className="text-field-3 w-input" maxLength={256} name="section_title" onChange={handleInputChange}
                                            data-name="Name 2" placeholder="Section" type="text" id="section_title" value={fields?.section_title}/><label
                        htmlFor="sub_section_title" className="text-block-139-bd-f-soc">Subsection title</label><input
                        className="text-field-3 w-input" maxLength={256} name="sub_section_title" data-name="Name 2"
                        placeholder="Subsection" type="text" id="sub_section_title" value={fields.sub_section_title} onChange={handleInputChange}/><label htmlFor="unit_title"
                                                                                 className="text-block-139-bd-f-soc">Unit
                      title *</label><input className="text-field-3 date-input today w-input"
                                            maxLength={256} name="unit_title" onChange={handleInputChange} value={fields.unit_title} data-name="Name 2" placeholder="Unit"
                                            type="text" id="unit_title"/><label htmlFor="name-3"
                                                                            className="text-block-139-bd-f-soc">Content
                      type *</label>
                      <div data-current="Individual" data-easing="ease" data-duration-in={300} data-duration-out={100}
                           className="tabs-2 w-tabs">
                        <div className="tabs-menu-2 w-tab-menu">
                          <a data-w-tab="Individual"
                             className="invite-mode-tab-link w-inline-block w-tab-link w--current">
                            <div className="w-layout-hflex flex-block-13"><img src="/images/youtube.svg"
                                                                                        loading="lazy" alt
                                                                                        className="image-142 bigger"/>
                            </div>
                            <h5 className="heading-5">Youtube video</h5>
                          </a>
                          {/*<a data-w-tab="Bulk" className="invite-mode-tab-link w-inline-block w-tab-link">
                            <div className="w-layout-hflex flex-block-13"><img src="/images/upload-1.svg"
                                                                                        loading="lazy" alt
                                                                                        className="image-142"/>
                            </div>
                            <h5 className="heading-5">Scorm Xblock</h5>
                          </a>*/}
                        </div>
                        <div className="tabs-content w-tab-content">
                          <div data-w-tab="Individual" className="w-tab-pane w--tab-active"><label htmlFor="youtube_video_url"
                                                                                                   className="text-block-139-bd-f-soc">Youtube
                            Video URL *</label><input
                              className="text-field-3 date-input today w-input"
                              maxLength={256} name="youtube_video_url" value={fields.youtube_video_url} onChange={handleInputChange} data-name="Name 2" placeholder="Youtube URL" type="url"
                              id="youtube_video_url"/></div>
                          <div data-w-tab="Bulk" className="w-tab-pane">
                            <div className="w-layout-hflex flex-block-31"><label htmlFor="name-3"
                                                                                          className="text-block-139-bd-f-soc">Scorm
                              XBlock *</label>
                              <a href="#" className="link-block-25 w-inline-block"><img
                                  src="/images/paperclip-1.svg" loading="lazy" alt className="image-151"/>
                                <div>file-scorm-xblock101.zip</div>
                              </a>
                            </div>
                            <div
                                className="div-block-207 first active follow-user redeem-btn mg-l16 no-mg-left">
                              <div className="text-block-111 red-text cf">Upload</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
  );
};

export default StudioAuthoringCourseCreationViewContent;