export const uploadFileToCourseStudio = async (formData, courseKey) => {
    return new Promise((_resolve, _reject)=>{
        const baseURL = process.env.REACT_APP_IBL_STUDIO_URL
        const url = `${String(baseURL).endsWith("/") ? baseURL : baseURL+"/"}assets/${courseKey}/`
        fetch(url, {
            method:"POST",
            body:formData,
            credentials:'include'
        }).then((resp)=>{
            if(!resp.ok){
                _reject()
            }
            resp.text().then(_resolve)
        }).catch(_reject)
    })


}

export const createCourseAPI = async (courseData) => {
    return new Promise((_resolve)=>{
        const baseURL = process.env.REACT_APP_IBL_STUDIO_URL
        const url = `${String(baseURL).endsWith("/") ? baseURL : baseURL+"/"}api/ibl/manage/course/`
        fetch(url, {
            method:"POST",
            body:JSON.stringify(courseData),
            headers: {
                'Content-Type': 'application/json',
                //Authorization: `Token ${localStorage.getItem('axd_token')}`,
            },
            credentials:'include'
        }).then((resp)=>{
            if(!resp.ok){
                _resolve()
            }
            resp.json().then(_resolve)
        }).catch(_resolve)
    })
}

export const updateCourseMetaDataAPI = (courseData) => {
    return new Promise((_resolve)=>{
        const baseURL = process.env.REACT_APP_IBL_STUDIO_URL
        const url = `${String(baseURL).endsWith("/") ? baseURL : baseURL+"/"}api/ibl/v1/course_settings/`
        fetch(url, {
            method:"POST",
            body:JSON.stringify(courseData),
            headers:{
                'Content-Type': 'application/json',
            },
            credentials:'include'
        }).then((resp)=>{
            resp.json().then(_resolve)
        }).catch(_resolve)
    })
}

export const createCourseSection = (sectionData) => {
    return new Promise((_resolve)=>{
        const baseURL = process.env.REACT_APP_IBL_STUDIO_URL
        const url = `${String(baseURL).endsWith("/") ? baseURL : baseURL+"/"}xblock/`
        fetch(url, {
            method:"POST",
            body:JSON.stringify(sectionData),
            headers:{
                'Content-Type': 'application/json',
            },
            credentials:'include'
        }).then((resp)=>{
            resp.json().then(_resolve)
        }).catch(_resolve)
    })
}

export const createCourseExtraSection = (locatorAppend, sectionData) => {
    return new Promise((_resolve)=>{
        const baseURL = process.env.REACT_APP_IBL_STUDIO_URL
        const url = `${String(baseURL).endsWith("/") ? baseURL : baseURL+"/"}xblock/${locatorAppend}`
        fetch(url, {
            method:"POST",
            body:JSON.stringify(sectionData),
            headers:{
                'Content-Type': 'application/json',
            },
            credentials:'include'
        }).then((resp)=>{
            resp.json().then(_resolve)
        }).catch(_resolve)
    })
}
